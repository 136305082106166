import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { ApiContext, ResourceContext, SessionContext } from 'contexts'
import { UPLOAD_CONCURRENCY } from 'constants'

import UploadModal from 'components/upload/UploadModal'


let UPLOAD_ID = Date.now()  // used to provide a mostly unique id for uploads


export const UploadContext = createContext()
export default function UploadProvider({ children }) {
  //console.log('upload refresh')
  let { api } = useContext(ApiContext)
  let { cacheResource } = useContext(ResourceContext)
  let { user } = useContext(SessionContext)

  // upload management
  // file uploads are a 3-phase process: first a initial api call is made to
  // determine the feasibility of the requested upload; it is then followed by
  // 0<1<10000 part uploads that return an etag upon success, followed by a
  // final commit api call with the etags that will perform the final
  // feasibility check and restitch the file back together if possible
  // the initial api call is performed "synchronously" as it may require user
  // intervention (e.g. to solve name conflicts or free up some storage)
  // everything else is performed off the "main thread" and conflicts are
  // resolved automatically if possible; `uploads` keeps track of all files that
  // have been enqueued for upload and are either pending or their final status
  // was not yet acknowledged by the user; they are always visible
  let [uploads, setUploads] = useState([])

  // for the parts upload, we use an ordered FIFO queue that maps unopened xhr
  // requests created during the initial call to functions that will open the
  // connection and send the desired payload; upon activation, the xhrs are
  // moved from the queue into the active set and their bound function discarded
  // upon completion (successful or otherwise), an attempt is made to start up
  // another request from the queue (if available); the total size of the active
  // set is capped by `UPLOAD_CONCURRENCY` and likely also by the browser
  let {queue, active, shift} = useMemo(() => ({
    queue: new Map(), // binds xhrs to a start() function
    active: new Set(), // keeps track of active xhrs
    shift() {
      for(let [xhr, start] of queue.entries()) {
        if(active.size >= UPLOAD_CONCURRENCY)
          break // no more slots available

        // move from queue into active set
        queue.delete(xhr)
        active.add(xhr)

        // start and shift on complete
        xhr.onloadend = () => shift(active.delete(xhr))
        start()
      }
    }
  }), [])

  // this may be used to resolve name conflicts; it adds the current time to
  // the resource's name; this is used automatically in the commit phase of an
  // already confirmed upload in case another user has uploaded a different file
  // with the same name at its destination
  let getUniqueFilename = useCallback(name => {
    let suffix = new Date().toISOString()
    let pos = name.lastIndexOf('.')
    let [filename, ext] = ~pos ? [name.substring(0, pos), name.substring(pos)] : [name, '']
    return `${filename} ${suffix}${ext}`
  }, [])

  // starts uploading `file` on top of `target`, optionally using `name` if
  // successful, this function adds the upload to the queue; if `cb` is
  // specified, it is invoked once the upload is finished (one way or another)
  // and it will not shown up in the upload queue
  // TODO: split this up to either allow immediate uploads when `cb` is passed
  // or to somehow move the part to the top of the queue
  let enqueueUpload = useCallback(async (target, filename, file, cb) => {
    if(!target) {
      target = user.home // use home folder if destination not provided
      filename ??= file.name // a name must be provided in this case
    }

    // get destination urls for parts and commit
    let body = {
      type: file.type, // currently ignored but might be used in the future
      size: file.size, // needed for size check and upload part generation
    }
    if(filename)
      // if not set, `target` must be a file that will be replaced; otherwise,
      // it must be a folder that will host the new file with the given name
      // conflict resolution is not handled in this method; it is the caller's
      // responsibility to call this again with resolved parameters
      body.name = filename
    let spec = await api('POST', `files/${target.id ?? target}/`, body)

    // add to active uploads
    let xhrs = []
    let upload = {
      key: `ul-${UPLOAD_ID++}`,
      name: filename ?? file.name,
      transferred: 0,
      size: file.size,
      hidden: !!cb,
      status: 'pending',
      onAbort() {
        if(upload.status != 'pending') return

        // flush out any enqueued requests first to ensure that if the browser
        // synchronously calls onabort, it will not start them
        for(let xhr of xhrs)
          if(queue.has(xhr))
            queue.delete(xhr)

        // abort any active requests
        for(let xhr of xhrs)
          if(active.has(xhr)) {
            xhr.abort()
            active.delete(xhr)
          }
        upload.status = 'error'
        setUploads(prev => [...prev])
      }
    }
    setUploads(prev => [...prev, upload])

    // add parts to upload queue
    let parts = []
    for(let {url, start, end} of spec.parts) {
      let xhr = new XMLHttpRequest()
      xhrs.push(xhr)
      parts.push(new Promise((resolve, reject) => {
        xhr.onload = () => {
          // make sure that we notify progress if the browser forgets to
          xhr.upload.onprogress({loaded: end - start})
          resolve(xhr.getResponseHeader('etag'))
        }
        xhr.onerror = xhr.onabort = reject

        let previous = 0
        xhr.upload.onprogress = ({loaded}) => {
          upload.transferred += loaded - previous
          previous = loaded
          setUploads(prev => [...prev])
        }
      }))
      queue.set(xhr, () => {
        xhr.open('PUT', url)
        xhr.send(file.slice(start, end))
      })
      shift()
    }

    // commit upload once all parts are complete
    Promise.all(parts).then(async parts => {
      let body = {parts}
      // eslint-disable-next-line no-constant-condition
      while(true)
        try {
          let resource = await api('POST', spec.commit, body)
          cacheResource(resource)
          upload.status = 'success'
          cb && cb(resource)
          return setUploads(prev => [...prev])
        } catch(err) {
          // destination folder was deleted; upload to the home folder instead
          if(err.code == 'conflict.folder' && !body.folder)
            body.folder = user.home
          // name conflict in destination folder; choose a new unique name
          else if(err.code == 'conflict.name' && !body.name)
            body.name = getUniqueFilename(filename ?? file.name)
          // bail out if any other error
          else throw err
        }
    }).catch(err => {
      // TODO: snow a snack with more a more detailed error message
      console.error(err)
      upload.onAbort()
      cb && cb(err)
    })
  }, [api, user?.home, cacheResource,
      /*immutable: */
      active, queue, shift, getUniqueFilename])

  // the final commit url (third phase) of an upload is bound to the team that
  // the user initiated the request on behalf of; because of this, we have to
  // abort all uploads whenever the team is changed; usually, this is because
  // the user logged out, but post-MVP we should show a confirmation dialog if
  // the user is attempting to change teams while uploads are pending; for
  // similar reasons, we should bind a onbeforeunload event to suggest that the
  // user does not close the tab or navigate away until uploads are completed
  useEffect(() => setUploads(uploads => (setTimeout(() => {
    let count
    for(let upload of Object.values(uploads))
      if(upload.status == 'pending')
        upload.onAbort(count++)
    if(count)
      console.warn(`Aborted ${count} uploads due to user team switch`)
  }), uploads)), [user?.team])

  return (
    <UploadContext.Provider value={{
      getUniqueFilename,
      enqueueUpload
    }}>
      {children}
      <UploadModal uploads={uploads} setUploads={setUploads}/>
    </UploadContext.Provider>
  )
}
