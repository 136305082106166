import { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useRef } from 'react'
import { MAX_CACHE_LIVE_REFRESH } from 'constants'


const KEY_MISSING = [null, Infinity] // returned if no key is provided (fresh)
const VALUE_MISSING = [null, 0] // returned if there's no value for a key (stale)
const DEFAULT_KEY_FUNC = id => id
const DEFAULT_MAPPER = value => value


function useCache(key, staleAfter=0, defaultValue=null, mapper=DEFAULT_MAPPER) {
  /* subscribes to `key` and returns a [key, stale, error] tuple that is kept
  in sync with the cache status at any given time; caveats:
  * errors are always fresh, regardless of when they were issued
  * unless `staleAfter` is supplied, the value is considered to always be stale
  * `defaultValue` is following useState() semantics, that is to say it is
    immutable for the lifetime of the component
  * if supplied, the `mapper` function is applied to the result whenever it or
    the result changes; this is usually useful when you want to `enrich` your
    cached object with some methods that may depend on different state
  */
  let { get, subscribe } = useContext(CacheContext)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let cachedDefault = useMemo(() => defaultValue, []) // staleness is intentional

  // can't use setState directly because it retains the previous value even when
  // the key is changed; as such, we only use it to trigger a component refresh
  // and store the real value in a ref
  let [, refresh] = useReducer(x => x+1, 0)
  let result = useRef()
  let update = useCallback(([value, stale]) => result.current = value instanceof Error
    ? [cachedDefault, stale, value]
    : [mapper(value) ?? cachedDefault, stale, null]
  , [mapper, /*immutable: */ cachedDefault])

  // similarly, useEffect is invoked post-render which means that we can't rely
  // on its effects during render; to prevent always-stale-defaults from being
  // generated initially and possibly triggering unnecessary server fetches, we
  // use a memo to grab the cached value on param update prior to subscribing
  useMemo(() => {
    let [value, timestamp] = get(key)
    update([value, timestamp + staleAfter <= Date.now()])
  }, [key, staleAfter, update, /*immutable: */ get])
  useEffect(() =>
    subscribe(key, staleAfter, value => refresh(update(value)))
  , [key, staleAfter, update, /*immutable: */ subscribe])

  return result.current
}


function useCacheMap(ids, staleAfter=0, keyFunc=DEFAULT_KEY_FUNC, mapper=DEFAULT_MAPPER) {
  /* dereferences a list of ids into a dictionary that maps them to the
  associated cached value using the following rules:

  1. the default value is a stale empty object {}
  2. cached values are mapped to their original _id_ in the output object; their
     cache keys may differ if a `keyFunc` is provided
  3. cached values are always mapped to their latest (most recent) version
  4. values not found in the cache are not present in the output map
  5. if any of the values is stale or missing, the entire map is stale
  6. if any of the values is an error, the result is empty and a random error
     is chosen as the error
  7. errors can be fresh or stale, depending on their timestamp
  8. if supplied, the mapper applies on individual items, not on the entire map
  */
  let { get, subscribe } = useContext(CacheContext)

  // similar to above, but instead of a ref we use a mutable map that is
  // re-created whenever the parameters change...
  let values = useMemo(() => {
    let values = new Map()
    let now = Date.now()
    for(let id of ids ?? []) {
      let [value, timestamp] = get(keyFunc(id))
      let stale = timestamp + staleAfter <= now
      values.set(id, [value instanceof Error ? value : mapper(value), stale])
    }
    return values
  }, [ids, staleAfter, keyFunc, mapper, /*immutable: */ get])

  // ...and kept in sync via the effect...
  let [revision, refresh] = useReducer(x => x+1, 0)
  useEffect(() => {
    let subscriptions = (ids ?? []).map(id =>
      subscribe(keyFunc(id), staleAfter, ([value, stale]) =>
        refresh(values.set(id, [value instanceof Error ? value : mapper(value), stale]))
    ))
    return () => subscriptions.forEach(unsubscribe => unsubscribe())
  }, [ids, staleAfter, keyFunc, mapper, values, /*immutable: */ subscribe])

  // ...if the map is changed or updated, we copy it to a immutable object that
  // can trigger effects
  return useMemo(() => {
    let result = {}, stale = false, error = null
    for(let [id, [value, isStale]] of values.entries()) {
      if(isStale)
        stale = true
      if(value instanceof Error) {
        error = value
        result = {}
        break
      } else if(value !== null)
        result[id] = value
    }
    return (revision, [result, stale, error])
  }, [revision, values])
}


export const CacheContext = createContext()
export default function CacheProvider({persisted, storage, children}) {
  /* Implements a bound cache on top of `storage` (localStorage-compatible)
  by evicting random* keys whenever a storage exception is raised; if present,
  the keys from `persisted` are never evicted, though this might cause the
  cache to be completely useless if the persisted keys use up all the quota

  *the actual eviction strategy is controlled by the engine; keys are evicted
  in the sequential order provided by `STORAGE_ENGINE.key(i)` with 0<=i<len */
  //console.log('cache refresh')
  let subscriptions = useMemo(() => new Map(), [])
  let memory = useMemo(() => new Map(), [])

  let invoke = useCallback((map, callback, [value, timestamp]) => {
    let [staleAfter, timer] = map.get(callback)

    // set a timer to invoke the callback again once the value becomes stale
    clearTimeout(timer)
    let becomesStaleAfter = timestamp + staleAfter - Date.now()
    if(0 < becomesStaleAfter && becomesStaleAfter < MAX_CACHE_LIVE_REFRESH) {
      timer = setTimeout(
        () => invoke(map, callback, [value, timestamp]),
        becomesStaleAfter
      )
      map.set(callback, [staleAfter, timer])
    }

    try { callback([value, becomesStaleAfter <= 0]) }
    catch(err) {
      console.log('Error in cache subscription');
      console.error(err)
    }
  }, [])

  let cache = useMemo(() => ({
    useCache,
    useCacheMap,
    get(key) {
      /* returns the `value` bound to `key` as well as its update timestamp;
      returns VALUE_MISSING if not found */
      if(!key) return KEY_MISSING
      let value = memory.get(key)
      if(typeof value == 'undefined') {
        let encoded = storage.getItem(key)
        value = encoded !== null ? JSON.parse(encoded) : VALUE_MISSING
        memory.set(key, value)
      }
      return value
    },
    subscribe(key, staleAfter, callback) {
      /* triggers `callback([val, stale])` whenever the value bound to `key`
      is updated, or on a timer when it becomes stale returns a lambda that will
      unsubscribe and remove the timers (useEffect-compatible) */
      if(!key) {
        //callback(KEY_MISSING)
        return () => {}
      }
      let callbacks = subscriptions.get(key)
      if(!callbacks)
        subscriptions.set(key, callbacks = new Map())
      callbacks.set(callback, [staleAfter, 0])
      //  invoke(callbacks, callback, cache.get(key))
      return () => {
        clearTimeout(callbacks.get(callback)[1])
        callbacks.delete(callback)
        if(!callbacks.size)
          subscriptions.delete(key)
      }
    },
    notify(key, value) {
      /* notifies all subscribers of `key` that `value` has changed; does not
      persist `value` nor does it update timestamp; this is usually called by
      the on(storage) event handler but also used to trigger incremental updates
      from paginated requests; in that situation we want immediate feedback, but
      don't want to persist incomplete data */
      return cache.update(key, value, false)
    },
    update(key, value, persist=true, timestamp=null) {
      /* triggers all subscriptions on `key` with `value`

      `key` must be a `string and `value` must be json serializable if `persist`
      is true (the default); the `(key, value)` pair may not actually be stored
      if there's insufficient space available, but will always be stored in
      memory and the subscriptions will always be called regardless

      if `value` is `null` or, any existing key is removed instead
      if `value` is a function, it is called with the previous cached value for
      the `key` (or `null` if it does not exist) as well as its last timestamp
      (or 0) and the above rules apply to its (immediate) result instead

      if `persist` is false, this only updates the volatile memory and will not
      store (or remove) the value from the underlying storage; subscriptions
      will still be called regardless; this is useful to notify an Error or
      trigger partial updates

      if `timestamp` is not null, its value is used to update the associated
      timestamp; this timestamp is used by subscribers to determine if the
      cached value for a key is stale; defaults to `null` which means that the
      old timestamp is kept (or 0 if the value was missing previously)
      */
      let notify = value => {
        let callbacks = subscriptions.get(key)
        for(let callback of callbacks?.keys() ?? [])
          invoke(callbacks, callback, value)
      }

      let old = (typeof value == 'function' || !persist) && cache.get(key)
      if(typeof value == 'function')
        value = value(...old) // unpack this for developer friendliness
      if(value === null) {
        if(persist) {
          memory.delete(key)
          storage.removeItem(key)
        }
        notify(VALUE_MISSING)
      } else {
        // attach current timestamp to value if persistent, otherwise use the
        // previous timestamp, if any; the value is always notified regardless
        value = [value, timestamp === null ? old[1] : timestamp]
        // throw immediately if attempted to persist a non-encodable value
        let encoded = persist && JSON.stringify(value)
        memory.set(key, value)
        notify(value)

        // persist
        if(!persist) return
        let evict = 0
        do {
          try {
            // optimistically add to cache and return
            return storage.setItem(key, encoded)
          } catch(err) {
            // out of space, remove the current key if not reserved
            let key = storage.key(evict++)
            if(!persisted.has(key))
              storage.removeItem(key)
          }
        } while(evict < storage.length)
        console.warn('Cache is trashing')
      }
    },
    store(key, value) {
      /* persistently binds `key` to `value` and updates the timestamp */
      return cache.update(key, value, true, Date.now())
    },
    error(key, err) {
      /* notifies all subscribers that `key` cannot be loaded and updates the
      timestamp in memory but does not persist the value to storage */
      return cache.update(key, err, false, Date.now())
    },
    expire(key) {
      /* sets a key's timestamp to null, effectively marking it as stale without
      removing it from the cache */
      return cache.update(key, prev => prev, true, 0)
    },
    flush() {
      /* flush the cache, without deleting any of the reserved keys */
      memory.clear()
      let stored = new Map()
      for(let key of persisted) {
        let val = storage.getItem(key)
        val && stored.set(key, val)
      }
      storage.clear()
      for(let [key, val] of stored.entries())
        storage.setItem(key, val)
    }
  }), [storage, persisted, /*immutable: */ subscriptions, memory, invoke])

  // notify cross-tab events
  useEffect(() => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event
    let onChange = ({key, newValue, storageArea}) => {
      if(storageArea === storage) {
        let [value, timestamp] = newValue ? JSON.parse(newValue) : VALUE_MISSING
        cache.update(key, value, false, timestamp)
      }
    }
    window.addEventListener('storage', onChange)
    return () => window.removeEventListener('storage', onChange)
  }, [storage, cache])

  return <CacheContext.Provider value={cache}>{children}</CacheContext.Provider>
}
