import UploadItem from './UploadItem'

import styles from './UploadList.module.scss'


export default function UploadList({ uploads, setUploads }) {
  return (
    <div className={styles.container}>
      {uploads.map(upload => upload.hidden ? null :
        <UploadItem
          {...upload}
          onDelete={() => setUploads(prev => prev.filter(item => item.key != upload.key))}
        />
      )}
    </div>
  )
}
